<template>
  <div>
    <b-modal id="modal-1" :title="rs_detail.nama" ok-disabled no-close-on-esc hide-footer size="lg">
      <b-row>
        <b-col md="10">
          <b-form-group>
            <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama_ruas_jalan"
              size="sm" placeholder="Pilih ruas jalan yg akan ditambahkan" :options="daftar_ruas"
              @input="tabahRuas(selected, rs_detail.id_bobot_penilaian)" />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" block size="sm" @input="tabahRuas(selected, rs_detail.id_bobot_penilaian)">
            <feather-icon icon="PlusIcon" size='15' /> Tambah Ruas Jalan
          </b-button>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <Tabel_ruas_jalan :rs_detail=dataList @HapusData=HapusData></Tabel_ruas_jalan>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import axios from '@/config/Axios'
import vSelect from 'vue-select'
import Base from '@/config/Mixins_base'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BFormInput, BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Tabel_ruas_jalan from './tabel_ruas_jalan.vue'

export default {
  components: {
    BButton,
    BModal,
    BAlert,
    Tabel_ruas_jalan, BRow, BCol, vSelect, BFormInput, BFormGroup
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected: {}
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["tabahRuas"],
  methods: {

    close() {
      this.$root.$emit('bv::hide::modal-1', 'AddLevel')
    },
    async HapusData(id_ruas_jalan) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/nilai_tambah/delete',
        data: {
          id_bobot_penilaian: self.rs_detail.id_bobot_penilaian,
          id_jalan: id_ruas_jalan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.dataList = response.data.result;
          self.notification('warning', "", response.data.message);
          self.$emit('tabahRuas', id_ruas_jalan)
          self.selected = {}

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async tabahRuas(val1, val2) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/nilai_tambah/insert',
        data: {
          id_bobot_penilaian: val2,
          id_jalan: val1.id_ruas_jalan
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.dataList = response.data.result;
          self.notification('warning', "", response.data.message);
          self.$emit('tabahRuas', val1, val2)
          self.selected = {}

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {
    rs_detail: {},
    daftar_ruas: {}
  },
  computed: {
    dataList: {
      get: function () {
        return this.rs_detail.ruas_jalan;
      },
      set: function (newValue) {
        return this.rs_detail.ruas_jalan = newValue;
      }
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>