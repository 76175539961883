<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <Tabel_nilai_tambah :rs_data=rs_data @detail_data=detail_data></Tabel_nilai_tambah>
          <Kelola_ruas_jalan :rs_detail=rs_detail :daftar_ruas=daftar_ruas @tabahRuas=tabahRuas></Kelola_ruas_jalan>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs,
  BTab, BCard, BRow, BCol
} from 'bootstrap-vue'
import Tabel_nilai_tambah from './component/tabel_nilai_tambah.vue'
import Kelola_ruas_jalan from './component/kelola_ruas_jalan.vue'
export default {
  components: {
    BTabs,
    BTab, BCard, BRow, BCol,
    Tabel_nilai_tambah,
    Kelola_ruas_jalan
  },
  data() {
    return {
      rs_data: [],
      rs_detail: {},
      daftar_ruas: []
    }
  },
  mixins: [Base],
  mounted() {
    this.load_data();
    this.get_daftar_ruas();
  },
  methods: {
    tabahRuas() {
      this.load_data();
    },
    detail_data(val) {
      this.rs_detail = val
    },
    async get_daftar_ruas() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/nilai_tambah/daftar_ruas',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.daftar_ruas = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async load_data() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/nilai_tambah/load_data',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs_data = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>